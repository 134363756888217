import React, { useState } from 'react';


export default function Advertiser() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  
    return (
    <>
        <div className="relative shadow-lg z-10">
        <div className=" relative p-12 bg-orange-100 shadow-lg">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <img src='/oases_logo_new.png' alt="image" className="logoheight w-auto" />
                </div>
                <div className="hidden sm:flex space-x-2">
                    <a href='/'><button className="px-4 py-2 hover:bg-orange-300 text-black rounded"><span className=" text-xl">Home</span></button></a>
                    <a href='/publishers'><button className="px-4 py-2 hover:bg-orange-300 text-black rounded"><span className=" text-xl">Publishers</span></button></a>
                    <a href='/advertisers'><button className="px-4 py-2 hover:bg-orange-300 text-black rounded"><span className=" text-xl">Brand and Agencies</span></button></a>
                </div>
                <div className="sm:hidden">
                    <button
                    className="flex items-center px-3 py-2 border rounded text-neutral-950 border-neutral-950"
                    onClick={toggleMenu}
                    >
                        <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path
                            fillRule="evenodd"
                            d="M3 9h14a1 1 0 010 2H3a1 1 0 010-2zm0-4a1 1 0 010-2h14a1 1 0 010 2H3zm0 8a1 1 0 010-2h14a1 1 0 010 2H3z"
                            clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    {menuOpen && (
                    <div className="absolute top-full left-0 w-full bg-orange-100 drop-shadow-2xl">
                        <a href='/'><button className="px-4 py-2 hover:bg-orange-300 text-black rounded"><span className=" text-xl">Home</span></button></a>
                        <a href='/publishers'><button className="px-4 py-2 hover:bg-orange-300 text-black rounded"><span className=" text-xl">Publishers</span></button></a>
                        <a href='/advertisers'><button className="px-4 py-2 hover:bg-orange-300 text-black rounded"><span className=" text-xl">Brand and Agencies</span></button></a>
                    </div>
                    )}
                </div>
            </div>
        </div>
        </div>
 
        <div className="relative p-7 px-12 bg-orange-100">
        <div className="main-body text-center ">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 class="mb-4 text-4xl font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white ">Reach a newly accessible and highly valuable target market for your brand</h1>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        </div>
        
        <div className='relative p-7 px-12 bg-orange-100'>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          
          <div className="main-body text-center ">
            <h1 class="mb-4 text-2xl font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-white font-semibold uppercase">How does it work?</h1>
          </div>
        </div>

        <div className='relative p-7 px-12 bg-orange-100'>
                        
        <div className="container mx-auto">
      <div className="overflow-x-auto">
        <div className="table-responsive">
          <table className="min-w-full">
            <tbody>
              <tr className="flex flex-wrap sm:flex-nowrap">
                <td className="px-6 py-4 w-full sm:w-1/3">
                  <div className="border-solid border-2 bg-[#9E0024] text-white bg-orange-300 border-black rounded-lg h-96 p-4 mt-4 overflow-hidden flex flex-col justify-center items-center">
                    <h1 className='text-4xl font-bold underline'>STEP 1</h1>
                    <br/>
                    <br/>
                    <br/>
                    <p className="text-2xl text-center overflow-ellipsis">Choose your target market based on demographic and geographic characteristics</p>
                    </div>
                </td>
                <td className="px-6 py-4 w-full sm:w-1/3">
                <div className="border-solid border-2 bg-[#9E0024] text-white bg-orange-300 border-black rounded-lg h-96 p-4 mt-4 overflow-hidden flex flex-col justify-center items-center">
                    <h1 className='text-4xl font-bold underline'>STEP 2</h1>
                    <br/>
                    <br/>
                    <br/>
                    <p className="text-2xl text-center overflow-ellipsis">Upload the ad copy, image, and or video that you would like published</p>
                    </div>
                </td>
                <td className="px-6 py-4 w-full sm:w-1/3">
                <div className="border-solid border-2 bg-[#9E0024] text-white border-black rounded-lg h-96 p-4 mt-4 overflow-hidden flex flex-col justify-center items-center">
                    <h1 className='text-4xl font-bold underline'>STEP 3</h1>
                    <br/>
                    <br/>
                    <br/>
                    <p className="text-2xl text-center overflow-ellipsis">Track your campaign’s performance with our custom analytics tools </p>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

        </div>

        <div className='relative p-7 px-12 bg-orange-100'>
        <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          
          <div className="main-body text-center ">
            <h1 class="mb-4 text-2xl font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-white font-semibold uppercase">Publishing Partners</h1>
          </div>
          <div className="container mx-auto">
            <div className="overflow-x-auto">
              <div className="table-responsive">
                <table className="min-w-full">
                  <tbody>
                    <tr className="flex flex-wrap sm:flex-nowrap">
                      <td className="px-6 py-4 w-full sm:w-1/3">
                        {/* <h2 className="text-xl text-center font-semibold uppercase tracking-wider">Audience</h2>
                        <div className="flex justify-center mt-4">
                          <img src="/audience.png" alt="Audience" className="w-20 h-20" />
                        </div>
                        <div className="border-solid border-2 bg-orange-300 border-black rounded-lg h-48 p-4 mt-4 overflow-hidden flex justify-center items-center">
                          <p className="text-l text-center overflow-ellipsis">Newly accessible, tried and tested group of consumers made up of residents and homeowners.</p>
                        </div> */}
                      </td>
                      <td className="px-6 py-4 w-full sm:w-2/3">
                        {/* <h2 className="text-xl text-center font-semibold uppercase tracking-wider">Inventory</h2> */}
                        <div className="flex justify-center mt-4">
                          <img src="/CondoA.png" alt="Inventory" className="w-screen h-auto" />
                        </div>
                        {/* <div className="border-solid border-2 bg-orange-300 border-black rounded-lg h-48 p-4 mt-4 overflow-hidden flex justify-center items-center">
                          <p className="text-l text-center overflow-ellipsis">Customized ad units built to integrate natively within a publisher’s established user interface.</p>
                        </div> */}
                      </td>
                      <td className="px-6 py-4 w-full sm:w-1/3">
                        {/* <h2 className="text-xl text-center font-semibold uppercase tracking-wider">Purpose</h2>
                        <div className="flex justify-center mt-4">
                          <img src="purpose.png" alt="Purpose" className="w-20 h-20" />
                        </div>
                        <div className="border-solid border-2 bg-orange-300 border-black rounded-lg h-48 p-4 mt-4 overflow-hidden flex justify-center items-center">
                          <p className="text-l text-center overflow-ellipsis">Connecting users to much-needed and highly vetted services and products that will improve their everyday life.</p>
                        </div> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='relative flex justify-center items-center p-7 px-12 bg-orange-100 ' >
          
          <div className="w-full max-w-md mt-16" >
            <h1 class="mb-4 text-m font-bold leading-none tracking-tight text-gray-900 md:text-l lg:text-xl dark:text-white font-semibold uppercase text-center">Explore advertising through Oases</h1>
            <br/>
            <br/>
            <div className="mb-4 text-left">
              <label className="block text-gray-700 text-xs md:text-l lg:text-l font-semibold " htmlFor="email">
                Email (required)
              </label>
              <br/>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" />
            </div>
            <div class="flex text-center items-center justify-center">
              <button class="bg-gray-900 hover:bg-gray-700 text-white text-xs md:text-l lg:text-l font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                GET IN TOUCH
              </button>
            </div>
          </div>
        </div>

        <footer className="bg-orange-100 py-4 px-6 sm:flex sm:justify-between">
          <div className="mb-2 sm:mb-0">
            <span className="text-gray-900 text-xs md:text-l lg:text-l">Email: </span>
            <a href="mailto:info@example.com" className="text-gray-900 text-xs md:text-l lg:text-l">info@oases-io.com</a>
          </div>
          <div className="text-gray-900">
            <a href="#" className="mr-2 text-xs md:text-l lg:text-l">Terms &amp; Conditions</a>
            <span>|</span>
            <a href="#" className="ml-2 text-xs md:text-l lg:text-l">Privacy Policy</a>
          </div>
        </footer>

    </>
    );
}