import React, { useState } from 'react';
 
import './MainPage.css'

export default function MainPage() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  
    return (
    <>
        <div className="relative shadow-lg z-10">
        </div>
 
        <div className="relative p-7 px-12 bg-orange-100">
        <div className="main-body text-center ">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 class="mb-4 text-4xl font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white ">Oases is a premium ad network disrupting the current digital marketing landscape</h1>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 class="mb-4 text-2Xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-white ">JOIN THE MOVEMENT!</h1>       
        </div>
        </div>
        
        <div className='flex justify-center items-center p-7 bg-orange-100' >
        
          <img src='/oases_logo_new.png' alt="image" className="h-auto w-auto" />
          <br/>
          <br/>
          <br/>
        </div>

        <footer className="bg-orange-100 py-4 px-6 sm:flex sm:justify-between">
          <div className="mb-2 sm:mb-0">
            <span className="text-gray-900 text-xs md:text-l lg:text-l">Email: </span>
            <a href="mailto:info@example.com" className="text-gray-900 text-xs md:text-l lg:text-l">info@oases-io.com</a>
          </div>
        </footer>

    </>
    );
}