import logo from './logo.svg';
import './App.css';
import Form from './components/Form';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage';
import Publisher from './components/Publisher.js';
import Advertiser from './components/Advertiser.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/reset-password' element={<Form/>} /> */}
        <Route path='/' element={<MainPage/>} />
        <Route path='/publishers' element={<Publisher/>} />
        <Route path='/advertisers' element={<Advertiser/>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
